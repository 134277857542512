const zoneColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: true,
    headerTitle: 'Publisher Name',
    sortable: false,
  },
  {
    key: 'BannerzoneId',
    label: 'Banner Zone ID',
    show: false,
    headerTitle: 'Banner Zone ID',
    sortable: true,
    thStyle: 'width:130px',
    tdClass: 'text-right',
  },
  {
    key: 'BannerzoneName',
    label: 'Banner Zone Name',
    show: true,
    headerTitle: 'Banner Zone Name',
    sortable: false,
  },
  {
    key: 'VideozoneId',
    label: 'Video Zone ID',
    show: false,
    headerTitle: 'Video Zone ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
  },
  {
    key: 'VideozoneName',
    label: 'Video Zone Name',
    show: true,
    headerTitle: 'Video Zone Name',
    sortable: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: false,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: true,
    headerTitle: 'RTB Zone Name',
    sortable: false,
  },
  {
    key: 'CC',
    label: 'Country',
    show: false,
    headerTitle: 'Country',
    sortable: true,
    thStyle: 'width: 85px',
    class: 'text-center',
  },
  {
    key: 'AppBundle',
    label: 'App Bundle',
    show: true,
    headerTitle: 'App Bundle',
    sortable: true,
  },
  {
    key: 'Cat',
    label: 'Cat',
    show: true,
    headerTitle: 'Cat',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
    thStyle: 'width:70px',
  },
  {
    key: 'Ivt',
    label: 'IVT',
    show: true,
    headerTitle: 'IVT',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
  },
  {
    key: 'Sivt',
    label: 'SIVT',
    show: true,
    headerTitle: 'SIVT',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
  },
  {
    key: 'Requests',
    label: 'Requests',
    show: true,
    headerTitle: 'Requests',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
  },
  {
    key: 'AdResponses',
    label: 'Ad Responses',
    show: true,
    headerTitle: 'Ad Responses',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
  },
  {
    key: 'Coverage',
    label: 'Coverage',
    show: true,
    headerTitle: 'Coverage',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:90px',
  },
  {
    key: 'Bids',
    label: 'Bids',
    show: true,
    headerTitle: 'Bids',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
  },
  {
    key: 'Wins',
    label: 'Wins',
    show: false,
    headerTitle: 'Wins',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Bills',
    label: 'Bills',
    show: false,
    headerTitle: 'Bills',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Losses',
    label: 'Losses',
    show: false,
    headerTitle: 'Losses',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Impressions',
    label: 'Impressions',
    show: true,
    headerTitle: 'Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
  },
  {
    key: 'NetImpressions',
    label: 'Net Impressions',
    show: true,
    headerTitle: 'Net Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
  },
  {
    key: 'FilterRate',
    label: 'Filter Rate',
    show: true,
    headerTitle: 'Filter Rate',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:90px',
  },
  {
    key: 'Clicks',
    label: 'Clicks',
    show: true,
    headerTitle: 'Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:90px',
  },
  {
    key: 'NetClicks',
    label: 'Net Clicks',
    show: true,
    headerTitle: 'Net Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
  },
  {
    key: 'IpBlock',
    label: 'IP Block',
    show: true,
    headerTitle: 'IP Block',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:80px',
    summed: true,
  },
  {
    key: 'IpMismatch',
    label: 'IP Mismatch',
    show: false,
    headerTitle: 'IP Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'UaMismatch',
    label: 'UA Mismatch',
    show: false,
    headerTitle: 'UA Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'EmptyReferer',
    label: 'Empty Referer',
    show: false,
    headerTitle: 'Empty Referer',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'RefererMismatch',
    label: 'Referer Mismatch',
    show: false,
    headerTitle: 'Referer Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'Ecpm',
    label: 'eCPM',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
  },
  {
    key: 'Spent',
    label: 'Spent',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
  },
  {
    key: 'Revenue',
    label: 'Revenue',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
  },
  {
    key: 'Profit',
    label: 'Profit',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
  },
]
export default zoneColumns
